import React, { useEffect, useState } from "react";
import "./SeedOrderBill.css"; // Importing the CSS
import { useParams } from "react-router-dom";
import { API_ENDPOINT } from "../../../constants/constants";
import {
  getDateFormat,
  getToken,
  subtractDaysFromDate,
} from "../../../utils/util";
import axios from "axios";

const SeedOrderBill = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}admin/get-full-order/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setOrder(response.data.order);
      } catch (err) {
        setError(
          err.response ? err.response.data.error : "Unexpected error occurred"
        );
      }
    };
    fetchOrder();
  }, [orderId]);

  // Function to format date
  const getDateFormat = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  if (error) return <p>Error: {error}</p>;
  if (!order) return <p>Loading...</p>;

  // Assuming chawki_date is in the format 'YYYY-MM-DD'
  const chawkiDate = new Date(order.chawki_date);
  const releasedDate = new Date(chawkiDate);
  releasedDate.setDate(releasedDate.getDate() - 13); // 13 days before chawki date
  const blackboxDate = new Date(chawkiDate);
  blackboxDate.setHours(blackboxDate.getHours() - 72); // 72 hours before chawki date

  return (
    <div className="bill_container">
      <div className="header_info">
        <div style={{ display: "flex" }}>
          <div style={{ fontSize: "14px" }}>
            Reg No: {order.supplierDetails.licence_number}
          </div>
          <div style={{ flex: 1, textDecoration: "underline" }}></div>
          <div style={{ fontSize: "14px" }}>
            GST: {order.supplierDetails.gstin}
          </div>
        </div>
        <div style={{ textAlign: "center", textDecoration: "underline" }}>
          PAYMENT RECEIPT
        </div>
        <div>{order.supplierDetails.company?.toUpperCase()}</div>
        <div>
          {`${order.supplierDetails.building}, ${order.supplierDetails.area}, ${order.supplierDetails.taluk}, ${order.supplierDetails.district}, ${order.supplierDetails.pin}`}
        </div>

        <div>Phone: {order.supplierDetails.phone}</div>
      </div>

      <div class="bill_no_line">
        <table>
          <tr>
            <td>Order ID: {order.order_id}</td>
            <td></td>
            <td>Date: {getDateFormat(new Date(order.createdOn))}</td>
          </tr>
          <tr>
            <td>To: {order.userDetails.company}</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div class="bill_items">
        <table>
          <tr>
            <th>Particulars</th>
            <th>Lot No.</th>
            <th>Quantity</th>
            <th>Rate(100DFLS)</th>
            <th>Amount(₹)</th>
          </tr>
          {order.userDetails.user_type === "asr" ? (
            <>
              <tr>
                <td>P1(FC1/FC2)</td>
                <td></td>
                <td>{order.quantity_required}</td>
                <td>{order.price}</td>
                <td>
                  {(
                    (order.price * order.quantity_required) /
                    100
                  ).toLocaleString()}
                </td>
              </tr>
            </>
          ) : (
            <>
              {order.lots?.map((lot, i) => (
                <tr>
                  <td>
                    {order.seed_type === "BV" ? "Bivoltine" : "Cross Breed"}
                  </td>
                  <td>{lot.lot_no}</td>
                  <td>{lot.quantity}</td>
                  <td>{order.price}</td>
                  <td>
                    {((order.price * lot.quantity) / 100).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr>
                <td>Transportation Charge</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{order.shipping_charge}</td>
              </tr>
              <tr>
                <td>Blackbox Charge</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{order.blackbox_charge}</td>
              </tr>
            </>
          )}
          <tr>
            <td colspan="4">Total</td>

            <td>
              {(
                (order.price * order.quantity_required) / 100 +
                (order.shipping_charge ?? 0) +
                (order.blackbox_charge ?? 0)
              ).toLocaleString()}
            </td>
          </tr>
        </table>
      </div>
      {/* <div class="disclaimer">
        Released On: 12/12/2023(13 days before chawki date)
      </div>
      <div class="disclaimer">
        Blackbox On: 22/12/2023 (72 hours before chawki date)
      </div>
      <div class="disclaimer">
        Expected Date of Hatching On: 25/12/2023 - 26/12/2023 (same as chawki
        date)
      </div> */}
      {order.seed_type === "BV" && (
        <div>
          <div className="disclaimer">
            Released On: {getDateFormat(releasedDate)}
          </div>
          <div className="disclaimer">
            Blackbox On: {getDateFormat(blackboxDate)}
          </div>
        </div>
      )}

      <div className="disclaimer">
        Expected Date of Hatching On: {getDateFormat(chawkiDate)}
      </div>
      {order.seed_type === "CB" && (
        <div className="disclaimer">
          Date of expirty: {getDateFormat(releasedDate)}
        </div>
      )}
      <div className="disclaimer">
        Note: Supplier & Silk Cart will not be responsible for any damage during
        transportation
      </div>
      <div className="bill_footer">
        This bill is system generated through Silk Cart, no signature required.
      </div>
    </div>
  );
};

export default SeedOrderBill;
